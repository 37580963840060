<template>
  <footer>
    <div id="com_name">社團法人 貓咪也瘋狂公益協會/貓瘋非營利慈善貓專科醫院</div>
    <div>105台北市松山區八德路四段216號 | (02)2910-3877</div>
    <div>每日營業時間 14:00-21:00</div>
    <div>
      ★捐款帳戶★中國信託 中崙分行 (銀行代碼 822) | 帳號 093540294558 |
      戶名：社團法人貓咪也瘋狂公益協會
    </div>
    <div>
      捐款後，如需收據，請email後面資訊到
      <a
        href="mailto:service@crazycat.org.tw"
        style="text-decoration: none"
        target="_blank"
        >service@crazycat.org.tw</a
      >
      <a
        href="https://www.facebook.com/crazycatcharity2"
        style="text-decoration: none"
        target="_blank"
        ><img id="messenger" title="到粉絲團傳訊息去" src="../style/messenger.png" /></a
      >「金額／末五碼／捐款日／捐款者姓名／電話／地址」
    </div>
    <div>
      ★本會資料：內政部核准設立案號：台內團字第1070034819號 |
      法人登記證書登記簿第149冊1頁3165號
    </div>
    <div>
      <a href="https://sasw.mohw.gov.tw/app39/solicitView/index" target="_blank"
        >勸募許可文號：衛部救字第1131364699號</a
      >
      | 國稅局扣繳單位統一編號：82194481
    </div>
    <div></div>
    <div id="footer_fbyoutube_icon"></div>
  </footer>
</template>

<script>
export default {};
</script>

<style></style>
